// App.jsx

import React, { useEffect, useState } from 'react';
import setupDatabase from './database';
import { Provider } from "react-redux";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
    Navigate
} from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";
import store from "./redux/configureStore";

import Home from "./components/Home";
import OrderInterface from "./components/OrderInterface";
import OrdersListInterface from "./components/OrdersListInterface";
import Login from "./components/Login";
import { login } from "./redux/userSlice";
import CashRegister from "./components/CashRegister";
import CashRegistersInterface from "./components/CashRegistersInterface";
import KitchenDisplayMonitor from "./components/KitchenDisplayMonitor";

function RedirectBasedOnAuthStatus() {
    const navigate = useNavigate();
    const location = useLocation();

    /**
     * Checks if the user's token is valid.
     * - If missing or invalid, redirects to /login.
     * - If valid, returns true.
     */
    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // No token at all -> go to /login
            navigate("/login");
            return false;
        }
        try {
            const response = await fetch(`https://api.flashpass.com.ar/validate-jwt`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + token,
                }
            });
            if (response.status === 200) {
                return true;
            } else {
                // Token is invalid -> remove it and go to /login
                localStorage.removeItem('token');
                navigate("/login");
                return false;
            }
        } catch (error) {
            console.error("Error validating token:", error);
            localStorage.removeItem('token');
            navigate("/login");
            return false;
        }
    };

    useEffect(() => {
        /**
         * 1) Validate the token
         * 2) If valid, check session flags (kdm / cashier_kdm)
         * 3) If none match, check session_id
         * 4) Possibly redirect to /home or do nothing
         */
        const checkAndRedirect = async () => {
            const isValid = await validateToken();
            if (!isValid) return; // Already redirected to /login if invalid

            // Token is valid
            const session = JSON.parse(localStorage.getItem("session")) || {};

            // Check if both are true => tables
            if (session.kdm === true && session.cashier_kdm === true) {
                if (location.pathname !== "/tables-printing-service") {
                    navigate("/tables-printing-service");
                }
                return;
            }

            // If only kdm => go tables
            if (session.kdm === true && location.pathname !== "/tables-printing-service") {
                navigate("/tables-printing-service");
                return;
            }

            // If only cashier_kdm => go cashiers
            if (session.cashier_kdm === true && location.pathname !== "/cashiers-printing-service") {
                navigate("/cashiers-printing-service");
                return;
            }

            // If neither, or user is not forced to KDM. Next check session_id logic
            const noSessionId = !session.session_id;
            const notOnAllowedRoute = ![
                "/cash-register-interface",
                "/pending-printing",
                "/tables-printing-service",
                "/cashiers-printing-service"
            ].includes(location.pathname);

            // If there's no open session, but user is on random route => /home
            if (noSessionId && notOnAllowedRoute) {
                navigate("/home");
            }
            // else do nothing, let them remain
        };

        checkAndRedirect();
    }, [navigate, location.pathname]);

    return null;
}

function App() {
    const [db, setDb] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const getToken = localStorage.getItem('token');

    // Put token in Redux if it exists
    useEffect(() => {
        if (getToken) {
            store.dispatch(login({ token: getToken }));
        }
    }, [getToken]);

    // Setup local DB
    useEffect(() => {
        async function initializeDB() {
            const database = await setupDatabase();
            setDb(database);
        }
        initializeDB();
    }, []);

    // Listen for offline/online
    useEffect(() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    const updateOnlineStatus = () => {
        setIsOnline(navigator.onLine);
    };

    // Possibly sync data if back online
    useEffect(() => {
        if (isOnline) {
            // syncDataWithAPI();
        }
    }, [isOnline, db]);

    // Prevent mouse wheel from changing number inputs
    useEffect(() => {
        document.addEventListener(
            'wheel',
            function(event) {
                if (document.activeElement.type === 'number') {
                    event.preventDefault();
                }
            },
            { passive: false }
        );
    }, []);

    // Example: Order route with param
    const OrderId = () => {
        let { id } = useParams();
        return <OrderInterface id={id} />;
    };

    const lightTheme = createTheme({ palette: { mode: 'light' }});

    return (
        <Provider store={store}>
            <div className="App">
                <ThemeProvider theme={lightTheme}>
                    <BrowserRouter>
                        {/*
              This component re-runs on route changes to ensure the user:
              - Has a valid token
              - Is redirected to the correct KDM if session flags are set
            */}
                        <RedirectBasedOnAuthStatus />

                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/cash-register" element={<CashRegister />} />
                            <Route path="/cash-register-interface" element={<CashRegistersInterface />} />
                            <Route path="/orders" element={<OrdersListInterface />} />
                            <Route path="/orders/:id" element={<OrderId />} />
                            <Route path="/orders/new" element={<OrderInterface />} />

                            {/* KDM routes */}
                            <Route
                                path="/tables-printing-service"
                                element={<KitchenDisplayMonitor kdmType="tables" />}
                            />
                            <Route
                                path="/cashiers-printing-service"
                                element={<KitchenDisplayMonitor kdmType="cashiers" />}
                            />

                            {/* pending-printing => redirect to /cashiers-printing-service */}
                            <Route
                                path="/pending-printing"
                                element={<Navigate to="/cashiers-printing-service" replace />}
                            />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </div>
        </Provider>
    );
}

export default App;
