export function updateSession(updates) {
    let sessionData = {};
    try {
        const storedData = localStorage.getItem('session');
        if (storedData) {
            sessionData = JSON.parse(storedData);
        }
    } catch (error) {
        console.error('Error parsing session data:', error);
    }

    // Update existing keys or add new ones
    Object.keys(updates).forEach((key) => {
        sessionData[key] = updates[key];
    });

    // Store back into localStorage
    localStorage.setItem('session', JSON.stringify(sessionData));
}

export function removeSession(keys) {
    // If `keys` is not provided, remove the entire session from localStorage
    if (typeof keys === 'undefined') {
        localStorage.removeItem('session');
        return;
    }

    // If a single string key was provided, wrap it in an array
    if (!Array.isArray(keys)) {
        keys = [keys];
    }

    let sessionData = {};
    try {
        const storedData = localStorage.getItem('session');
        if (!storedData) {
            // No session to remove from
            return;
        }
        sessionData = JSON.parse(storedData);
    } catch (error) {
        console.error('Error parsing session data:', error);
        // If invalid, nothing to remove
        return;
    }

    // Remove the specified keys from the session object
    keys.forEach((key) => {
        delete sessionData[key];
    });

    // If after removal the session data is empty, remove the entire session
    // else, store the updated session
    if (Object.keys(sessionData).length === 0) {
        localStorage.removeItem('session');
    } else {
        localStorage.setItem('session', JSON.stringify(sessionData));
    }
}

export function closeSession() {
    // Remover token y session
    localStorage.removeItem('token');
    localStorage.removeItem('session');

    // Redirigir al login
    window.location.href = '/login';
}