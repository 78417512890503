import React, { useEffect, useState } from 'react';
import '../styles/PasswordInput.css';
import { FiDelete } from "react-icons/fi";

const PasswordInput = (props) => {
    const maxPasscodeLength = 6; // e.g., 6-digit code

    // Local state (child controls passcode)
    const [passcode, setPasscode] = useState(props.password || '');

    // On-screen keypad layout
    // "C" => clear, "⨉" => backspace
    const keys = ['1','2','3','4','5','6','7','8','9','C','0', <FiDelete />];

    // -- Utility: set local passcode & notify parent (only if changed) --
    const setLocalPasscode = (newVal) => {
        // If same as current, do nothing
        if (newVal === passcode) return;

        setPasscode(newVal);
        // If parent's password is different, update it
        if (props.password !== newVal) {
            props.setPassword(newVal);
        }
    };

    // Called by on-screen keypad
    const updatePasscode = (key) => {
        if (key === 'C') {
            // Clear everything
            setLocalPasscode('');
        }
        else if (key === '⨉') {
            // Backspace one char
            setLocalPasscode(passcode.slice(0, -1));
        }
        else if (passcode.length < maxPasscodeLength) {
            // Append digit
            setLocalPasscode(passcode + key);
        }
    };

    // Build an array of key objects for the on-screen keypad
    const keysWithHandlers = keys.map((keyVal) => {
        // If it's the icon (<FiDelete />), we interpret as "⨉"
        const actualKey = typeof keyVal === 'string' ? keyVal : '⨉';
        const safeClassName = typeof keyVal === 'string'
            ? keyVal.replace(/\W/g, '')
            : 'icon';

        return {
            display: keyVal,
            onPress: () => updatePasscode(actualKey),
            className: `key-password-button-container key-class-${safeClassName}`
        };
    });

    // Listen for parent changes to props.password
    // Only pull into local state if different
    useEffect(() => {
        if (props.password !== passcode) {
            setPasscode(props.password || '');
        }
    }, [props.password]);

    // Handle physical keyboard events
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (props.isLoading) return; // ignore if loading

            switch (event.key) {
                case 'Enter':
                    // Submit if we have something typed
                    if (passcode.length > 0) {
                        props.submitFunction();
                    }
                    break;

                case 'Backspace':
                case 'Delete':
                    // Remove last char
                    setLocalPasscode(passcode.slice(0, -1));
                    break;

                case 'Escape':
                    // Clear all
                    setLocalPasscode('');
                    break;

                default:
                    // If it's a digit 0-9, append (up to max length)
                    if (/^[0-9]$/.test(event.key)) {
                        if (passcode.length < maxPasscodeLength) {
                            setLocalPasscode(passcode + event.key);
                        }
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [passcode, props.isLoading, props.submitFunction]);

    // -- RENDER --
    return (
        <div className="password-container">

            {/* Passcode Display */}
            <div className="row">
                <div className="col-12">
                    <div className="passcode-display">
                        {passcode === "" ? (
                            <p className="mb-0" style={{ fontSize: "16px", color: "black" }}>
                                {props.placeholder}
                            </p>
                        ) : (
                            // Show dots for typed chars, up to maxPasscodeLength
                            Array.from({ length: maxPasscodeLength }).map((_, index) => (
                                <div key={index} className="passcode-dot">
                                    {index < passcode.length ? "•" : ""}
                                </div>
                            ))
                        )}
                    </div>
                    <hr style={{ margin: '0.7rem 0' }} />
                </div>
            </div>

            {/* On-screen keypad buttons */}
            <div className="row">
                <div className="col-12">
                    <div className="key-password-buttons-grid">
                        {keysWithHandlers.map((item, idx) => (
                            <button
                                key={idx}
                                className={item.className}
                                onClick={item.onPress}
                            >
                                <div className="key-password-button">
                                    {item.display}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Confirm button */}
            <div className="row">
                <div className="col-12">
                    <button
                        className={`password-submit-button ${!passcode ? "disabled" : ""}`}
                        onClick={props.submitFunction}
                        disabled={!passcode || props.isLoading}
                    >
                        CONFIRMAR
                    </button>
                </div>
            </div>

        </div>
    );
};

export default PasswordInput;
