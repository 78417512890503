// Login.jsx

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'; // For SweetAlert2
import PasswordInput from "./PasswordInput";
import { useNavigate } from "react-router-dom";
import { updateSession } from "../utils/session";
import { removeVenue } from "../utils/utils";
import {HiSwitchHorizontal} from "react-icons/hi"; // Your custom function to remove localStorage venue

const Login = () => {
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [venueId, setVenueId] = useState(JSON.parse(localStorage.getItem("venue")));
    const [isLoading, setIsLoading] = useState(false);

    const submitVenueId = async () => {
        setIsLoading(true);
        const request = await fetch(`https://api.flashpass.com.ar/venue?code=${password}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });

        const response = await request.json();
        setIsLoading(false);

        if (response.status === "success") {
            setPassword("");
            if (response.data) {
                localStorage.setItem("venue", JSON.stringify(response.data));
                setVenueId(response.data);
            }
        }
    };

    const submitPasscode = async () => {
        setIsLoading(true);
        const request = await fetch(`https://api.flashpass.com.ar/menu-collaborator-auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                passcode: password,
                venue: venueId.id,
                login_user: true,
            }),
        });

        const response = await request.json();
        setIsLoading(false);

        if (response.status === "success") {
            setPassword("");
            localStorage.setItem("token", response.token);
            updateSession({ kdm: response?.kdm, cashier_kdm: response?.cashier_kdm });

            if (response.kdm === true) {
                navigate("/tables-printing-service");
            } else {
                navigate("/home");
            }
        }
    };

    // Show a SweetAlert to confirm removing the current venue
    const handleChangeVenue = () => {
        Swal.fire({
            title: '¿Desea cambiar de sucursal?',
            text: 'Esta acción eliminará la sucursal actual para ingresar una nueva.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cambiar sucursal',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                removeVenue();     // Remove from localStorage
                setVenueId(null);  // Clear state so user can enter a new venue ID
            }
        });
    };

    return (
        <div className="container">
            {venueId && (
                <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
                    <button className="option-top-bar" onClick={handleChangeVenue}>
                        <HiSwitchHorizontal className="me-1 d-none d-md-block"/> Cambiar Sucursal
                    </button>
                </div>
            )}

            <div className="row align-content-center" style={{height: "100vh"}}>
                <div className="col-md-6 p-5 align-items-center text-center">
                <div className="password-container">
                        <div className="row">
                            <div className="col-12">
                                {venueId && venueId.logo ? (
                                    <img
                                        className="img-fluid"
                                        src={venueId.logo}
                                        alt={venueId.name}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid"
                                        src="/flashpass_logo512.png"
                                        alt="FlashPass"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <PasswordInput
                                password={password}
                                setPassword={setPassword}
                                venueId={venueId}
                                placeholder={
                                    venueId
                                        ? "Ingrese su contraseña"
                                        : "Ingrese el ID de sucursal"
                                }
                                submitFunction={!venueId ? submitVenueId : submitPasscode}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
