// CashRegistersInterface.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PosOptionBox from "./PosOptionBox";
import { MdAddShoppingCart, MdPointOfSale } from "react-icons/md";
import {IoArrowBack} from "react-icons/io5";

const CashRegistersInterface = () => {
    const navigate = useNavigate();

    // Navigate back to /home
    const handleBack = () => {
        navigate('/home');
    };

    return (
        <div>
            <div className="home-container">
                {/* "Volver" button at top */}
                <button
                    onClick={handleBack}
                    className={"back-button"}
                >
                    <IoArrowBack size={20} /> Volver
                </button>

                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center d-flex">
                        <PosOptionBox
                            icon={<MdPointOfSale />}
                            text={"Gestión de caja"}
                            to={`/cash-register`}
                            size={200}
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center d-flex">
                        <PosOptionBox
                            icon={<MdAddShoppingCart />}
                            text={"Ordenes"}
                            to={`/orders/new`}
                            size={200}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashRegistersInterface;
