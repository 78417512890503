import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from "@mui/material";
import Swal from 'sweetalert2';               // <-- for confirmation popup
import { closeSession } from '../utils/session'; // <-- your custom logout util
import PanelBarraOrderComponent from "./PanelBarraOrderComponent";
import { useNavigate } from "react-router-dom";
import { triggerPrinting } from "./functions/printOrder";
import '../styles/KDM.css';
import { FaCog, FaHome, FaUser } from "react-icons/fa";
import { FaShop, FaCalendar } from "react-icons/fa6";
import { fetchEvents } from "../utils/events";

const KitchenDisplayMonitor = ({ kdmType }) => {
    const navigate = useNavigate();

    // Main data states
    const [orders, setOrders] = useState([]);
    const [events, setEvents] = useState([]);
    const [activeEvent, setActiveEvent] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);
    const token = localStorage.getItem('token');

    // PRINTING SERVICE STATES
    const [pendingPrinting, setPendingPrinting] = useState([]);
    const [printedUrls, setPrintedUrls] = useState([]);
    const [historicalUrls, setHistoricalUrls] = useState([]);
    const historicalUrlsRef = useRef([]);
    const [pos, setPos] = useState([]); // Will store either tables or POS registers
    const [selectedTables, setSelectedTables] = useState([]); // Same array for checkboxes
    const isPrintingRef = useRef(false);

    //----------------------------------------------------------------
    // 1. Fetch all available events
    //----------------------------------------------------------------
    const getEvents = async () => {
        try {
            const fetchedEvents = await fetchEvents();
            if (Array.isArray(fetchedEvents)) {
                setEvents(fetchedEvents);
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    //----------------------------------------------------------------
    // 2. Fetch "Tables" or "Cashiers" depending on kdmType
    //----------------------------------------------------------------
    const fetchTables = async () => {
        if (!activeEvent) return;
        try {
            const request = await fetch(
                `https://api.flashpass.com.ar/venue-tables?event=${activeEvent}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + token,
                    },
                }
            );

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();
            if (result?.status === 'success') {
                setPos(result?.data || []);
            }
        } catch (error) {
            console.error('Failed to fetch tables:', error);
        }
    };

    const fetchPos = async () => {
        if (!activeEvent) return;
        try {
            const request = await fetch(
                `https://api.flashpass.com.ar/menu-pos?event=${activeEvent}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': 'Bearer ' + token,
                    },
                }
            );

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();
            if (result?.status === 'success') {
                // Convert API pos data to uniform array
                setPos(
                    result.registers?.map((posItem) => ({
                        id: posItem.pos_id,
                        name: posItem.pos_name
                    })) ?? []
                );
            }
        } catch (error) {
            console.error('Failed to fetch POS registers:', error);
        }
    };

    //----------------------------------------------------------------
    // 3. Handle event selection
    //----------------------------------------------------------------
    const handleEventChange = (e) => {
        setActiveEvent(e.target.value);
        setPos([]);
        setSelectedTables([]);
    };

    //----------------------------------------------------------------
    // 4. Fetch orders for the selected tables/POS
    //----------------------------------------------------------------
    const fetchData = async () => {
        try {
            if (selectedTables.length === 0) return;

            const posIdsParam = selectedTables.join(',');
            const request = await fetch(
                `${process.env.REACT_APP_API_URL}/menu-table-orders-kds?table=${posIdsParam}&kdmType=${kdmType}&event=${activeEvent}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!request.ok) {
                console.error(`Error: ${request.status}`);
            }

            const result = await request.json();
            if (result?.status === 'success') {
                // Only "processing" orders
                setOrders(result.data.filter((order) => order?.order_status === 'processing'));
            }
            setIsLoaded(true);
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    };

    //----------------------------------------------------------------
    // 5. Mark an order as delivered
    //----------------------------------------------------------------
    const deliverOrder = async (order_hash) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/menu-orders?order_id=${order_hash}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            const result = await response.json();
            // Refresh orders
            fetchData();
            return result;
        } catch (error) {
            console.error('Failed to deliver order:', error);
        }
    };

    //----------------------------------------------------------------
    // 6. Handle table/POS checkboxes
    //----------------------------------------------------------------
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedTables((prev) => [...prev, value]);
        } else {
            setSelectedTables((prev) => prev.filter((table) => table !== value));
        }
    };

    //----------------------------------------------------------------
    // 7. Fetch pending printing tasks
    //----------------------------------------------------------------
    const fetchPendingPrinting = async (posIds) => {
        try {
            const posIdsParam = posIds.join(',');
            const request = await fetch(
                `https://api.flashpass.com.ar/table-printing-service?table=${posIdsParam}&version=v2&kdmType=${kdmType}&event=${activeEvent}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                        'credentials': 'same-origin',
                    }
                }
            );

            if (!request.ok) {
                throw new Error(`Error: ${request.status}`);
            }

            const result = await request.json();

            // Filter out duplicates/printed in last 60s
            const now = Date.now();
            const newTasks = result.filter((task, index, self) =>
                !printedUrls.includes(task.url) &&
                !historicalUrlsRef.current.some(
                    (urlObj) => urlObj.url === task.url && now - urlObj.timestamp < 60000
                ) &&
                index === self.findIndex((t) => t.url === task.url)
            );

            // Add them to the queue
            setPendingPrinting((prev) => [...prev, ...newTasks]);

            // Start printing if not already
            if (!isPrintingRef.current && newTasks.length > 0) {
                isPrintingRef.current = true;
                triggerPrintingQueue([...newTasks]);
            }
        } catch (error) {
            console.error("Failed to fetch pending printing tasks:", error);
        }
    };

    //----------------------------------------------------------------
    // 8. Print tasks in sequence
    //----------------------------------------------------------------
    const triggerPrintingQueue = (tasks) => {
        if (tasks.length === 0) {
            isPrintingRef.current = false;
            return;
        }

        const [currentTask, ...remainingTasks] = tasks;

        // Mark current as printed
        setPrintedUrls((prev) => [...prev, currentTask.url]);
        setHistoricalUrls((prev) => {
            const newHistorical = [...prev, { url: currentTask.url, timestamp: Date.now() }];
            historicalUrlsRef.current = newHistorical;
            return newHistorical;
        });

        // Trigger the actual printing
        triggerPrinting(currentTask.order_hash, currentTask.url);

        // Remove from pending after 4s, then continue
        setTimeout(() => {
            setPendingPrinting((prevPending) =>
                prevPending.filter(task => task.url !== currentTask.url)
            );
            triggerPrintingQueue(remainingTasks);
        }, 4000);
    };

    //----------------------------------------------------------------
    // 9. On mount, fetch the event list
    //----------------------------------------------------------------
    useEffect(() => {
        getEvents();
    }, []);

    //----------------------------------------------------------------
    // 10. When activeEvent changes, fetch either tables or POS
    //----------------------------------------------------------------
    useEffect(() => {
        if (activeEvent) {
            if (kdmType === 'tables') {
                fetchTables();
            } else if (kdmType === 'cashiers') {
                fetchPos();
            }
        }
    }, [activeEvent, kdmType]);

    //----------------------------------------------------------------
    // 11. Every 4s, fetch orders & printing if any checkboxes are selected
    //----------------------------------------------------------------
    useEffect(() => {
        if (selectedTables.length > 0) {
            const fetchInterval = setInterval(() => {
                fetchPendingPrinting(selectedTables);
                fetchData();
            }, 4000);

            return () => clearInterval(fetchInterval);
        }
    }, [selectedTables]);

    //----------------------------------------------------------------
    // 12. Clean up older historical URLs every 10s
    //----------------------------------------------------------------
    useEffect(() => {
        const cleanupInterval = setInterval(() => {
            const now = Date.now();
            setHistoricalUrls((prev) => {
                const newHistorical = prev.filter((u) => now - u.timestamp < 60000);
                historicalUrlsRef.current = newHistorical;
                return newHistorical;
            });
        }, 10000);

        return () => clearInterval(cleanupInterval);
    }, []);

    //----------------------------------------------------------------
    // 13. Handle closing session
    //----------------------------------------------------------------
    const handleLogout = () => {
        Swal.fire({
            title: '¿Deseas cerrar sesión?',
            text: 'Esta acción te devolverá a la pantalla de Login.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar sesión',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                closeSession(); // Calls your custom utility
            }
        });
    };

    //----------------------------------------------------------------
    // RENDER
    //----------------------------------------------------------------
    return (
        <>
            {!isLoaded ? (
                <div className="container" style={{ minHeight: "100vh" }}>
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "25vh" }}
                    >
                        <CircularProgress color="secondary" size={100} />
                    </div>
                </div>
            ) : (
                <div className="row">
                    {/* SIDEBAR */}
                    <div className="col-3 col-lg-0">
                        <div className="sidebar_xyz123">
                            <ul className="sidebar-menu_xyz123">
                                {/* Evento */}
                                <li className="sidebar-menu-item_xyz123">
                                    <div className="sidebar-item-container_xyz123">
                                        <FaCalendar className="sidebar-icon_xyz123" />
                                        <span className="sidebar-text_xyz123">
                                            Evento
                                        </span>
                                    </div>
                                    <div className="sidebar-event-container_xyz123">
                                        <select
                                            id="eventSelect"
                                            onChange={handleEventChange}
                                            style={{ width: '100%' }}
                                            value={activeEvent}
                                        >
                                            <option value="">-- Seleccionar Evento --</option>
                                            {events.map((ev) => (
                                                <option key={ev.id} value={ev.id}>
                                                    {ev.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </li>

                                {/* Mesas / POS list */}
                                <li className="sidebar-menu-item_xyz123">
                                    <div className="sidebar-item-container_xyz123">
                                        <FaShop className="sidebar-icon_xyz123" />
                                        <span className="sidebar-text_xyz123">
                                            {kdmType === 'tables' ? 'Mesas' : 'POS'}
                                        </span>
                                    </div>
                                    <ul className="sidebar-submenu_xyz123">
                                        {pos?.map((tableOrPos) => (
                                            <li
                                                key={tableOrPos.id}
                                                className="sidebar-submenu-item_xyz123"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={tableOrPos.id}
                                                    value={tableOrPos.id}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label
                                                    htmlFor={tableOrPos.id}
                                                    className="sidebar-submenu-label_xyz123"
                                                >
                                                    {tableOrPos.table_name || tableOrPos.name || "N/A"}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                                {/* Cerrar Sesión */}
                                <li className="sidebar-menu-item_xyz123" onClick={handleLogout}>
                                    <div className="sidebar-item-container_xyz123">
                                        <FaUser className="sidebar-icon_xyz123" />
                                        <span className="sidebar-text_xyz123">
                                            Cerrar Sesión
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* MAIN CONTENT: Pedidos */}
                    <div className="col-9 col-lg-12">
                        <div className="container" style={{ minHeight: "100vh" }}>
                            <div className="row mt-2 justify-content-between align-items-center">
                                <div className="col">
                                    <h3>Pedidos</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    {orders
                                        ?.filter((_, i) => i % 3 === 0)
                                        .map((order, idx) => (
                                            <PanelBarraOrderComponent
                                                key={idx}
                                                order={order}
                                                deliverOrder={deliverOrder}
                                                printOrder={() =>
                                                    triggerPrinting(order?.order_hash, order?.order_url)
                                                }
                                            />
                                        ))}
                                </div>
                                <div className="col-md-4">
                                    {orders
                                        ?.filter((_, i) => i % 3 === 1)
                                        .map((order, idx) => (
                                            <PanelBarraOrderComponent
                                                key={idx}
                                                order={order}
                                                deliverOrder={deliverOrder}
                                                printOrder={() =>
                                                    triggerPrinting(order?.order_hash, order?.order_url)
                                                }
                                            />
                                        ))}
                                </div>
                                <div className="col-md-4">
                                    {orders
                                        ?.filter((_, i) => i % 3 === 2)
                                        .map((order, idx) => (
                                            <PanelBarraOrderComponent
                                                key={idx}
                                                order={order}
                                                deliverOrder={deliverOrder}
                                                printOrder={() =>
                                                    triggerPrinting(order?.order_hash, order?.order_url)
                                                }
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default KitchenDisplayMonitor;
