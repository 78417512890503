export const fetchEvents = async () => {
    const response = await fetch(`https://api.flashpass.com.ar/menu-events`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'credentials': 'same-origin',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const result = await response?.json();

    if (result.status === "success") {
        return result.events;
    } else {
        return [];
    }
}