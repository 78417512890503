// Home.jsx
import React, { useEffect, useState } from 'react';
import { MdPointOfSale } from "react-icons/md";
import '../styles/Home.css'; // Make sure this import follows your file structure
import PosOptionBox from "./PosOptionBox";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { closeSession, updateSession } from "../utils/session";
import { fetchEvents } from "../utils/events";
import { HiSwitchHorizontal } from "react-icons/hi";

const Home = () => {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [registers, setRegisters] = useState([]);
    const [events, setEvents] = useState([]);

    // Fetch POS registers
    const fetchRegisters = async () => {
        const response = await fetch(`https://api.flashpass.com.ar/menu-pos`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        const result = await response?.json();
        if (result.status === "success") {
            setRegisters(result.registers);
        }
    };

    // Fetch Events
    const getEvents = async () => {
        const eventsData = await fetchEvents() ?? [];
        setEvents(eventsData);
    };

    // Opens a new register session
    const openRegister = async (pos_id, event, amount) => {
        const response = await fetch(`https://api.flashpass.com.ar/menu-pos-sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                pos_id: pos_id,
                amount: amount,
                type: "open",
                event_id: event
            }),
        });

        const result = await response?.json();
        if (result.status === "success") {
            updateSession({
                pos_id: pos_id,
                session_id: result.session_id,
            });
        }
        return result.status;
    };

    // Handle register session (open or existing)
    const handleSession = (pos_id, session) => {
        if (session) {
            // If there's an existing session, store it and navigate
            updateSession({ pos_id, session_id: session });
            navigate('/cash-register-interface');
        } else {
            // Show the SweetAlert2 form to open a new session
            MySwal.fire({
                title: 'Apertura de caja',
                html: `
                    <div style="display: flex; flex-direction: column; align-items: stretch;">
                        <input id="swal-input1" class="swal2-input" placeholder="Monto inicial" type="number" style="margin-bottom: 20px;">
                        <select id="swal-input2" class="swal2-input">
                            <option value="" disabled selected>Seleccione un evento</option>
                            ${events.map(event => `<option value="${event.id}">${event.name}</option>`)}
                        </select>
                    </div>`,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                preConfirm: () => {
                    const amount = document.getElementById('swal-input1').value;
                    const event = document.getElementById('swal-input2').value;
                    if (!amount || !event) {
                        MySwal.showValidationMessage("Por favor, complete todos los campos.");
                        return false;
                    }
                    if (isNaN(amount) || parseFloat(amount) < 0) {
                        MySwal.showValidationMessage("Ingrese un monto inicial válido.");
                        return false;
                    }
                    if (event === "") {
                        MySwal.showValidationMessage("Seleccione un evento.");
                        return false;
                    }
                    return [amount, event];
                }
            }).then((result) => {
                if (result.value) {
                    const [amount, event] = result.value;
                    openRegister(pos_id, event, amount).then((resp) => {
                        if (resp === "success") {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Caja abierta',
                                text: 'La caja se abrió correctamente.',
                            }).then(() => {
                                navigate('/cash-register-interface');
                            });
                        } else {
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'No se pudo abrir la caja. Intente nuevamente.',
                            });
                        }
                    });
                }
            });
        }
    };

    // Confirmation for "Cambiar Usuario" button
    const handleChangeUser = () => {
        MySwal.fire({
            title: '¿Desea cambiar de usuario?',
            text: 'Esta acción cerrará la sesión actual y volverás al login.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cambiar usuario',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                closeSession(); // Removes token/session, then redirect to /login
            }
        });
    };

    // On mount, fetch registers/events
    useEffect(() => {
        const requests = setTimeout(() => {
            fetchRegisters();
            getEvents();
        }, 100);
        return () => clearTimeout(requests);
    }, []);

    return (
        <div className="home-container">
            {/* "Cambiar Usuario" button at top-right */}
            <button
                className="option-top-bar change-user-button"
                onClick={handleChangeUser}
            >
                <HiSwitchHorizontal className="me-1 d-none d-md-block" />
                Cambiar Usuario
            </button>

            <div className="row justify-content-center text-center">
                {registers.map((register) => (
                    <div key={register.pos_id} className="col-lg-3 col-md-4 col-6">
                        <PosOptionBox
                            icon={<MdPointOfSale />}
                            text={register.pos_name}
                            clickAction={() => handleSession(register.pos_id, register.session_id)}
                            size={200}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
